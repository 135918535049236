<template>
  <div id="app" class="oms-app">
    <a-locale-provider :locale="app_locale" prefixCls="erp">
      <a-spin class="global-spin" :spinning="$store.state.loading">
        <router-view />
      </a-spin>
    </a-locale-provider>
  </div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import { ConfigProvider } from 'ant-design-vue'
import 'moment/locale/zh-cn'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { setCookie } from '@common/cookie'
export default {
  components: {
    'a-locale-provider': ConfigProvider,
  },
  computed: {
    ...mapState(['lang', 'locale']),
    ...mapGetters(['currentShop']),
    app_locale() {
      return this.locale || zh_CN
    },
  },
  mounted() {
    let t = window.devicePixelRatio
    let rate = 1
    if (t >= 2) {
      document.body.style.zoom = t / 2.5
      rate = 3.1 / t
    }
    localStorage.setItem('zoomRate', rate)
    this.changeLang(this.lang)
    this.changeLanguage(this.lang)
    if (this.currentShop?.isExpire) {
      if (window.location.href.indexOf('intl') < 0) return //只有线上环境才提示
      let expireShopList = JSON.parse(localStorage.getItem('shopList')).filter(
        (item) => item.isExpire
      )
      let html = (
        <div>
          <p style="font-weight: bold">{this.$t('当前业务组下过期店铺列表')}:</p>
        </div>
      )
      expireShopList.forEach((item) => {
        html.children.push(<p style="margin-bottom: 0px">{item.name}</p>)
      })
      this.$confirm({
        title: this.$t('当前店铺过期，请联系管理员重新授权'),
        content: () => <div>{html}</div>,
        okText: this.$t('去授权'),
        cancelText: this.$t('取消'),
        onOk() {
          if (window.location.href.indexOf('test') > -1) {
            window.open('https://testidaas.shopspade.com/applicationList')
          } else {
            window.open('https://idaas.shopspade.com/applicationList?')
          }
        },
        onCancel() {
          console.log('Cancel')
        },
        class: 'test',
      })
    }
  },
  methods: {
    ...mapMutations(['setState', 'changeLang']),
    changeLanguage(lang) {
      this.$i18n.locale = lang
      let language_mapping = {
        zh: 'zh_CN',
        en: 'en_US',
        th: 'th_TH',
      }
      moment.locale(lang)
      setCookie({
        language: language_mapping[lang],
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
}
</style>
