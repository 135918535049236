import constants from './common/constants'
import './common/common'
import Vue from 'vue'
// 导入bootstrap
import App from './App.vue' // 根组件
import router from './router.js'
import store from './store'
import jsSHA from 'jssha'
import './css/index.scss'
import './utils/antd'
import { registerMicroApps, addErrorHandler } from 'qiankun'
import apps from './qiankun/apps.js'
import { Modal } from 'ant-design-vue'
import i18n from './utils/lang'

// 由于本身有window.__POWERED_BY_QIANKUN__参数，sub应用无法判断自己在第几层
// 设置一个全局参数，让sub应用检测到该参数则说明自己作为孙子应用运行
window.__POWERED_BY_QIANKUN_PARENT__ = true

registerMicroApps(apps)

// 预加载子应用
// prefetchApps([apps[0]])

Vue.prototype.$jsSHA = jsSHA

const env = 'dev'

Vue.config.devtools = env === 'dev' ? true : false
Vue.config.silent = env === 'dev' ? false : true

Vue.prototype.global = {}
Vue.prototype.constants = constants

Vue.prototype.global.currentSession = null
Vue.prototype.global.currentModule = null

Vue.prototype.$bus = new Vue()

// 添加错误处理函数
addErrorHandler((error) => {
  // 在这里对子应用加载错误进行处理
  console.error('子应用加载错误:', error)
  if (process.env.NODE_ENV === 'production') {
    Modal.error({
      title: i18n.t('页面出错了'),
      content: i18n.t('请尝试浏览器无痕浏览访问，仍不行请联系技术人员'),
    })
  }
})

let antLayoutContent

//路由跳转后，页面回到顶部
router.afterEach(() => {
  if (antLayoutContent) {
    antLayoutContent.scrollTop = 0
  } else {
    antLayoutContent = document.querySelector('.ant-layout-content')
    antLayoutContent && (antLayoutContent.scrollTop = 0)
  }
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
