// import { getCookie } from '@/common/cookie'

class HostUrl {
  constructor() {
    // if (!getCookie('d_session_id')) return
    // 从localStorage中获取当前IdaasApp
    this.apiURL = localStorage.getItem('apiURL')
  }

  getUrl(name) {
    if (process.env.NODE_ENV === 'development') {
      return '/' + (name ? name.toLocaleLowerCase() + '_api' : 'api')
    }
    return this.apiURL + (name ? `/${name.toLocaleLowerCase()}api` : '')
  }
}

export default HostUrl
