import { WMSEntry, OMSEntry, BIEntry } from '../config/config'
import actions from './actions'
import router from '@/router.js'
import store from '@/store'
import { logout, getSystemName, getSystemRoutePath } from '@/common'
import api from '@/service/api'
import http from '@/service/axios'
import deepClone from '../common/deepClone'

const microApps = [
  /**
   * name: 微应用名称 - 具有唯一性
   * entry: 微应用入口 - 通过该地址加载微应用
   * activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
   * container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
   */
  //OMS
  {
    name: 'OMS',
    entry: OMSEntry,
    container: '#product-viewport',
    activeRule: '/OMS',
  },
  //WMS
  {
    name: 'WMS',
    entry: WMSEntry,
    container: '#product-viewport',
    activeRule: '/WMS',
  },
  //BI
  {
    name: 'BI',
    entry: BIEntry,
    container: '#product-viewport',
    activeRule: '/BI',
  },
]

const apps = microApps.map((item) => {
  return {
    ...item,
    props: {
      parentRouter: router,
      routerBase: item.activeRule,
      getGlobalState: actions.getGlobalState,
      ...item.props,
      erpLogout: logout,
      erpJump: (key) => {
        switchApp(key)
      },
      showAppDrawer: () => {
        store.commit('setState', { drawerVisible: true })
      },
    },
    loader: (loading) => {
      if (loading) {
        store.commit('setState', { dashboard_loading: true, loading_type: item.name })
      } else {
        store.commit('setState', { dashboard_loading: false })
      }
    },
  }
})

export function switchApp(key, path) {
  store.commit('setState', { loading: true })
  let currentApp = deepClone(store.state.subMerchantAppList.find((elem) => elem.appCode === key))
  let menuList = currentApp.menuList,
    funcList = currentApp.funcList
  let userSession = {
    ...store.state.userSession,
    funcList,
  }
  let systemName = getSystemName(key),
    routePath = getSystemRoutePath(key)
  let url = `/${systemName}/${routePath}`
  let name = routePath.split('/').pop()
  delete currentApp.menuList
  delete currentApp.funcList
  store.commit('setState', { menuList, currentApp, loading: false, userSession })
  localStorage.setItem('panes', JSON.stringify([{ view: routePath, name }]))
  //切换系统时 由于还没切过去 只会调用当前系统的global监听
  actions.setGlobalState({
    menuList,
    currentApp,
  })

  if (path) {
    router.push(path)
  } else {
    router.push(url)
  }
  http({
    url: api.switchApp,
    type: 'post',
    data: {
      switchAppCode: key,
    },
  })
}

export default apps
