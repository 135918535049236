import moment from 'moment'
import i18n from '@/utils/lang'
export default {
  loading: false, //加载全局spin Loading
  lang: localStorage.getItem('lang') || 'zh',
  locale: getData('locale', {}),
  shopList: [], //店铺列表
  userInfo: getData('userInfo', {}),
  currentApp: getData('currentApp', {}),
  userSession: getData('userSession', {}),
  currentShop: getData('currentShop', {}),
  subMerchantAppList: getData('subMerchantAppList', {}),
  shopCode: '',
  nowPlatformList: [], //当前的平台列表，通过店铺来获取的。
  countryMap: {
    th: 1, //泰国
    TH: 1, //泰国
    Thailand: 1, //泰国
    VN: 2, //越南
    PH: 3, //菲律宾
    Philippines: 3, //菲律宾
    MY: 4, //马来西亚
    Malaysia: 4, //马来西亚
    CN: 6, //中国
    China: 6, //中国
  },
  exclude: null,
  panes: getData('panes', [{ view: 'saleOrders' }]),
  shopPlatformList: getData('shopPlatformList', []),
  pagination_setting: {
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
  },
  show_time_setting: {
    hideDisabledOptions: true,
    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
  },
  dashboard_loading: false,
  menus: getData('menus', []),
  orderDetailStateInfo: {},
  microApplicationList: new Map([]), // 已经注册的微应用列表
  show_micro: false, //展示子应用
  goodsTypeMapping: {
    GENERAL: i18n.t('成品'),
    SEMI_FINISHED_PRODUCT: i18n.t('半成品'),
    RAW_MATERIAL: i18n.t('原料'),
    VIRTUAL: i18n.t('虚拟商品'),
    GIFT: i18n.t('赠品'),
  },
  saleTypeMapping: {
    FRESHLY_MADE_AND_SOLD: i18n.t('现制现售'),
    STOCK_UP_FOR_SALE: i18n.t('备货销售'),
    PURCHASE_SALE: i18n.t('采购销售'),
  },
  rate:
    localStorage.getItem('zoomRate') ||
    (window.devicePixelRatio >= 2 ? 3.1 / window.devicePixelRatio : 1),
  drawerVisible: false,
}

function getData(name, defaultData) {
  let data = localStorage.getItem(name)
  if (data === undefined || data === 'undefined' || data === null || data === 'null') {
    return defaultData
  }
  try {
    return JSON.parse(data)
  } catch (err) {
    console.log(err)
  }
  return data
}
