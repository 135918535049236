const frontURL = "https://erp.geezdata.com"
const WMSEntry = 'https://wms.geezdata.com'
const OMSEntry = 'https://oms.geezdata.com'
const BIEntry = 'https://bi.shopspade.com'

const singleSignOnURL =
  'https://idaas.shopspade.com/api/open/oauth/authorize?app_key=spade_gateway_001&response_type=code&scope=ERP&terminal=PC&redirect_uri=' +
  frontURL
const singleSignOutURL = 'https://idaas.shopspade.com/logout'

export { frontURL, singleSignOnURL, singleSignOutURL, WMSEntry, OMSEntry, BIEntry }
